.toolbar {
    display: flex;
    margin-bottom: 1px;
    background: #fff;
    
    vertical-align: middle;
    width: 100%;
    /* border-bottom: 1px solid #eee; */
    flex-wrap: wrap;
    justify-content: center;
}
.toolbar-dropdown-menu {
    padding: 3 0;
    transform: translateX(40%);
}

.toolbar-dropdown-menu .toolbar-dropdown-row {
    padding-left: 5px;
    padding-right: 5px;
    justify-content: space-evenly;
}
.toolbar .toolbar-item {
    margin: 4px;
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
    line-height: 16px;
    color: #777;
}


.toolbar .toolbar-item:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.toolbar .toolbar-item.spaced {
    margin-right: 2px;
}

.toolbar .toolbar-item i.format {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
}

.toolbar .toolbar-item:disabled i.format {
    opacity: 0.2;
}

.toolbar .toolbar-item.active {
    background-color: #eee;
}

.toolbar .toolbar-item.active i {
    opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
    background-color: #eee;
}

.toolbar .divider {
    width: 1px;
    /* background-color: #eee; */
    margin: 0 4px;
}

.toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
}

.toolbar .toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
}